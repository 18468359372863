import { css } from '@emotion/css';
import format from 'date-fns/format';
import Link from 'gatsby-link';
import { lighten } from 'polished';
import React, { ReactElement } from 'react';
import { colours } from '../styles/variables';
import TagList from './TagList';

const postStyle = css`
  border: 1px solid #d9e5f5;
  border-radius: 5px;
  box-shadow: 2px 2px 10px ${lighten(0.4, colours.grey)};
  margin-bottom: 1rem;
  padding: 1.3rem;
  position: relative;

  @supports (display: grid) {
    margin: 0;

    &:only-child {
      grid-column: 1 / span 2;
    }
  }

  &::after {
    bottom: 0;
    box-shadow: 5px 5px 15px ${lighten(0.2, colours.grey)};
    content: '';
    display: block;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 300ms ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const dateStyle = css`
  color: ${colours.grey};
  font-size: 0.9rem;
  font-style: italic;
  margin-bottom: 0.5rem;
`;

interface Props {
  title: string;
  date: Date;
  description: string;
  link: string;
  tags: string[];
  timeToRead: number;
}

const PostPreview = ({
  title,
  date,
  description,
  link,
  tags = [],
  timeToRead,
}: Props): ReactElement => (
  <div className={postStyle}>
    <p className={dateStyle}>
      <time>{format(new Date(date), 'do MMMM')}</time>
      {` • ${timeToRead} min read`}
    </p>

    <h2>
      <Link to={link}>{title}</Link>
    </h2>

    <p>{description}</p>

    <TagList tags={tags} />
  </div>
);

export default PostPreview;

import { css } from '@emotion/css';
import format from 'date-fns/format';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import React, { ReactElement } from 'react';
import { textStyles } from '../styles/utils';
import { breakpoints } from '../styles/variables';
import { PostContent } from '../types/content';
import PostPreview from './PostPreview';

const postGridStyle = css`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

interface Props {
  posts: {
    node: PostContent;
  }[];
}

const PostList = ({ posts = [] }: Props): ReactElement => {
  if (posts.length === 0) {
    return <h1 className={textStyles.center}>No posts available to read.</h1>;
  }

  const orderedPosts = orderBy(
    posts.map(({ node }) => ({
      title: node.frontmatter.title,
      date: new Date(node.frontmatter.date),
      description: node.frontmatter.description,
      excerpt: node.excerpt,
      link: node.fields.slug,
      tags: node.frontmatter.tags,
      timeToRead: node.timeToRead,
    })),
    ['date'],
    ['desc'],
  );

  const groupedPosts = groupBy(orderedPosts, ({ date }) =>
    format(date, 'MMMM yyyy'),
  );

  return (
    <div>
      {Object.keys(groupedPosts).map((date) => (
        <div key={date}>
          <h3>{date}</h3>

          <div className={postGridStyle}>
            {groupedPosts[date].map((post) => (
              <PostPreview {...post} key={post.title} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostList;
